export const colourScemeMap = new Map()
colourScemeMap.set('AdviceAnimals', ['#9ee6b2', '#CBA328', '#CBA328'])
colourScemeMap.set('ooer', ['#f19c79', '#f19c79', '#cbdfbd'])
colourScemeMap.set('funny', ['#DDDDDD', '#FC6471', '#7D5BA6'])
colourScemeMap.set('cutouts', ['#cab6b5', '#191102', '#A29F15'])
colourScemeMap.set('meirl', ['#f19c79', '#a44a3f', '#cbdfbd'])
colourScemeMap.set('memes', ['#9ED0E6', '#B796AC', '#000000'])
colourScemeMap.set('MemeEconomy', ['#3b5998', '#444950', '#FFFFFF'])
colourScemeMap.set('shittyadviceanimals', ['#DDDDDD', '#FC6471', '#7D5BA6'])
colourScemeMap.set('ComedyCemetery', ['#cab6b5', '#191102', '#A29F15'])
colourScemeMap.set('teenagers', ['#f19c79', '#a44a3f', '#cbdfbd'])
colourScemeMap.set('PrequelMemes', ['#9ED0E6', '#B796AC', '#000000'])
colourScemeMap.set('terriblefacebookmemes', ['#f19c79', '#a44a3f', '#cbdfbd'])
colourScemeMap.set('2meirl4meirl', ['#f19c79', '#a44a3f', '#cbdfbd'])
colourScemeMap.set('historymemes', ['#f19c79', '#a44a3f', '#cbdfbd'])
